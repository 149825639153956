import { Controller } from "stimulus";
import sanitizeBarcode from "lib/sanitize_barcode";

export default class extends Controller {
  static get targets() {
    return [
      "output",
      "submitButton",
      "scannedCode",
      "codeNew",
      "scannable",
      "itemRow",
      "scanInfo",
      "selectOption",
      "manualControl",
    ];
  }

  connect() {
    this.resetAndFocus();
    this.handleSubmittable();
  }

  // Event waarbij een code wordt ingescand.
  //
  // Gaat over elke rij tot een toepasselijke rij wordt gevonden
  // en wijzigt de select indien mogelijk.
  //
  // Daarna gebeurt er nog:
  //  * feedback zowel auditief als visueel
  //  * reset van de form
  scan() {
    let scanState = "unknown";
    let name = "";
    let scannedCode = this.scannedCode;

    if (scannedCode.startsWith("2H")) {
      scannedCode = scannedCode.substring(2);
    }

    const matchingOptions = Array.apply(
      null,
      this.element.querySelectorAll("option")
    ).filter((o) => {
      return o.dataset.scannable === scannedCode;
    });

    if (matchingOptions.length > 0) {
      const availableOption = matchingOptions.find((o) => {
        return o.closest("select").value === "";
      });

      if (availableOption) {
        const select = availableOption.closest("select");
        const row = availableOption.closest("tr");

        select.value = availableOption.value;
        select.dispatchEvent(new Event("change"));

        // dit is een beetje jammer aangezien item dit ook moet doen
        // echter moeilijk te vermijden omdat itemController niet
        // bereikt kan worden (of moeilijk) + de pickingChange event zorgt
        // niet voor de handleScanFeedback
        if (select.value === row.dataset.choice) {
          scanState = "success";
        } else {
          scanState = "wrongChoice";
        }
      } else {
        name = matchingOptions[0].closest("tr").querySelector(".name")
          .innerText;
        scanState = "doubleScan";
      }
    }

    this.handleScanFeedback(scanState, scannedCode, name);

    this.resetAndFocus();
  }

  handleScanFeedback(scanState, scannedCode, name) {
    const playSound = (kind) => {
      const html = `<div data-controller='sound' data-sound='${kind}' />`;
      this.element.insertAdjacentHTML("beforeend", html);
    };

    const addTemplateRow = (id, scannedCode, name) => {
      const t = document.getElementById(id);
      const tb = this.element.querySelector("table tbody");
      const clone = document.importNode(t.content, true);

      const scannedCodeTd = clone.querySelector("td.scanned_code");
      scannedCodeTd.textContent = scannedCode;
      const nameTd = clone.querySelector("td.name");
      nameTd.textContent = name;

      tb.appendChild(clone);
      this.element.dispatchEvent(
        new CustomEvent("pickingChange", { bubbles: true })
      );
    };

    const setScanInfo = (scannedCode, name) => {
      this.scanInfoTarget.innerText = name || scannedCode;

      const states = ["success", "wrongChoice", "doubleScan", "unknown"];
      states.map((state) => {
        this.scanInfoTarget.classList.remove(
          "picking_widget__scan_info--" + state
        );
      });
      this.scanInfoTarget.classList.add(
        "picking_widget__scan_info--" + scanState
      );
    };

    setScanInfo(scannedCode, name);

    switch (scanState) {
      case "success":
        playSound("success");
        break;
      case "wrongChoice":
        playSound("error");
        break;
      case "doubleScan":
        playSound("warning");
        addTemplateRow("double_article_row", scannedCode, name);
        break;
      case "unknown":
        playSound("error");
        addTemplateRow("wrong_article_row", scannedCode, name);
        break;
      default:
        console.log("onbekende state: " + scanState);
        break;
    }
  }

  handleSubmittable() {
    const everythingDecided = this.itemRowTargets.every((el) => {
      return (
        el.dataset.scannedChoice !== undefined &&
        el.dataset.scannedChoice !== ""
      );
    });

    const hasNoScannedDouble = !this.element.querySelector(
      ".row--scannedDouble"
    );
    const hasNoScannedWrong = !this.element.querySelector(".row--scannedWrong");

    if (everythingDecided && hasNoScannedDouble && hasNoScannedWrong) {
      this.submitButtonTarget.removeAttribute("disabled");
      this.submitButtonTarget.classList.remove("disabled");
    } else {
      this.submitButtonTarget.setAttribute("disabled", "disabled");
      this.submitButtonTarget.classList.add("disabled");
    }
  }

  resetAndFocus() {
    this.scannedCodeTarget.value = "";
    this.scannedCodeTarget.focus();
  }

  removeFaultyItem(event) {
    event.target.closest("tr").remove();
    this.element.dispatchEvent(
      new CustomEvent("pickingChange", { bubbles: true })
    );
  }

  toggleManualControls(event) {
    this.manualControlTargets.forEach((el) => {
      el.classList.contains("hidden")
        ? el.classList.remove("hidden")
        : el.classList.add("hidden");
    });
  }

  get scannedCode() {
    return sanitizeBarcode(this.scannedCodeTarget.value);
  }
}
