import { Controller } from "stimulus"
import Swal from "sweetalert2"

export default class extends Controller {
  static get targets() {
    return ["descriptor"]
  }

  connect() {
    Swal.fire({
      icon: this.descriptorTarget.getAttribute("data-description-icon"),
      html: this.descriptorTarget.getAttribute("data-description-html"),
      confirmButtonText: "Bewaar",
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then(function (value) {
      if (value != null) {
        document.querySelector(".modal-form").submit()
      }
    })
  }
}
