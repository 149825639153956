import { Controller } from "stimulus";

export default class extends Controller {
  static get targets() {
    return ["input", "rowCheckbox"];
  }

  connect() {
    this.rowCheckboxChanged();
  }

  rowCheckboxChanged() {
    const disabled = !this.rowCheckboxTarget.checked;

    this.inputTargets.forEach((inputTarget) => {
      inputTarget.disabled = disabled;
    });
  }
}
