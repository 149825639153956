import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    src: String,
    interval: Number,
  };
  static targets = ["switch"];

  connect() {
    if (this.element.querySelector("table")) {
      this.autoRefreshFrame();
    } else {
      console.error(
        "FrameAutoRefresh Error: Expected table to be inside frame!"
      );
    }
  }

  disconnect() {
    clearTimeout(this.interval);
  }

  // This is based on the fact that when the src attribute is set, this is
  // picked up by Turbo and it autofetches the src url and swaps the content of
  // the frame.
  autoRefreshFrame() {
    this.interval = setInterval(() => {
      console.log("autorefresh tick");

      if (this.switchTarget.value !== "true") {
        this.disconnect();
        return;
      }

      if (this.element.getAttribute("src")) {
        this.element.removeAttribute("src");
      } else {
        this.element.setAttribute("src", this.srcValue);
      }
    }, this.intervalValue / 2);
  }
}
