import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return ["row", "order", "scannedChoice", "scannedChoiceValue"]
  }

  connect() {
    this.scanned = this.scanned.bind(this)

    // even uitstellen omdat anders de event de hogere controller niet bereikt
    window.setTimeout(this.scanned, 100)
  }

  scanned() {
    this.rowTarget.classList.remove(
      "row--decided",
      "row--scanned",
      "row--scannedOutOfStock",
      "row--scannedWrongChoice"
    )
    const selectedOption = this.scannedChoiceTarget.selectedOptions[0]
    this.element.dataset.scannedChoice = selectedOption.value

    const is_empty_selection = this.scannedChoiceTarget.selectedIndex === 0
    let info = ""

    if (!is_empty_selection) {
      let cssKlass
      switch (this.element.dataset.scannedChoice) {
        case "out_of_stock":
          cssKlass = "row--scannedOutOfStock"
          break
        case this.element.dataset.choice:
          cssKlass = "row--scanned"
          break
        default:
          cssKlass = "row--scannedWrongChoice"
      }

      this.rowTarget.classList.add(cssKlass)
      this.rowTarget.classList.add("row--decided")
      this.scannedChoiceValueTarget.textContent =
        (selectedOption.dataset.translation || "") + " " + info
    } else {
      this.scannedChoiceValueTarget.textContent = null
    }
    this.element.dispatchEvent(
      new CustomEvent("pickingChange", { bubbles: true })
    )
  }

  outOfStock() {
    const options = Array.from(this.scannedChoiceTarget.options)

    const outOfStockOption = options.find(option => {
      return option.dataset.scannable === "Niet voorradig"
    })

    this.scannedChoiceTarget.value = outOfStockOption.value
    this.scannedChoiceTarget.dispatchEvent(new Event("change"))
  }

  notReceived() {
    const options = Array.from(this.scannedChoiceTarget.options)

    const notReceivedOption = options.find(option => {
      return option.dataset.scannable === "Niet ontvangen"
    })

    this.scannedChoiceTarget.value = notReceivedOption.value
    this.scannedChoiceTarget.dispatchEvent(new Event("change"))
  }

  reset(event) {
    event.preventDefault()

    const select = this.element.querySelector("select")
    select.value = ""
    select.dispatchEvent(new Event("change"))
  }

  register_selection() {}
}
