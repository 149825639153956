import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    this.audio_context = new AudioContext();
  }

  connect() {
    this.playSound(this.element.getAttribute("data-sound"));

    // In order to every time reconnect again, we have to remove the data controller attribute, to trigger a disconnect
    this.element.removeAttribute("data-controller");
  }

  playSound(sound) {
    switch (sound) {
      case "success":
        this.beep(100, 520, 150);
        break;
      case "error":
        this.beep(999, 75, 500);
        break;
      case "warning":
        this.beep(500, 375, 500);
        break;
      default:
        console.error("Unsupported sound: ", sound);
    }
  }

  beep(vol, freq, duration) {
    var oscillator = this.audio_context.createOscillator();
    var gain = this.audio_context.createGain();
    oscillator.connect(gain);
    oscillator.frequency.value = freq;
    oscillator.type = "square";
    gain.connect(this.audio_context.destination);
    gain.gain.value = vol * 0.01;
    oscillator.start(this.audio_context.currentTime);
    oscillator.stop(this.audio_context.currentTime + duration * 0.001);
  }
}
