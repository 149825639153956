import { Controller } from "stimulus"
import Swal from "sweetalert2"

export default class extends Controller {
  static get targets() {
    return ["descriptor"]
  }

  connect() {
    Swal.fire(this.descriptorTarget.getAttribute("data-message"))
  }
}
