sanitizeBarcode = input => {
  const chars = {
    "&": 1,
    é: 2,
    '"': 3,
    "'": 4,
    "(": 5,
    "§": 6,
    è: 7,
    "!": 8,
    ç: 9,
    à: 0
  }
  const result = input.split("").map(char => {
    return chars.hasOwnProperty(char) ? chars[char] : char
  })

  return result.join("")
}

module.exports = sanitizeBarcode
