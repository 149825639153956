import { Controller } from "stimulus"
import sanitizeBarcode from "lib/sanitize_barcode"

export default class extends Controller {
  static get targets() {
    return ["isbnInput", "qtyInput", "form"]
  }

  connect() {
    console.log("scanner connected")
    this.isbnInputTarget.focus()
  }

  submitForm(e) {
    e.preventDefault()

    let isbn = this.isbnInputTarget.value
    this.isbnInputTarget.value = sanitizeBarcode(isbn)
    this.formTarget.submit()
  }

  selectInput(e) {
    this.isbnInputTarget.select()
  }
}
