import { Controller } from "stimulus"
import sanitizeBarcode from "lib/sanitize_barcode"

export default class extends Controller {
  static get targets() {
    return ["id"]
  }

  connect() {
    this.idTarget.focus()
  }

  startPicking(e) {
    e.preventDefault()

    window.location = "/admin/orders/" + this.scannedCode + "/pick"
  }

  checkPicking(e) {
    e.preventDefault()

    window.location +=
      "distribution_orders/" + this.scannedCode + "/check_picking"
  }

  get scannedCode() {
    return sanitizeBarcode(this.idTarget.value)
  }
}
