// controller for turbostream enabled rows
//
// As the frames are not able to be put right in the table, a go between
// is needed.
import { Controller } from "stimulus";

export default class extends Controller {
  static get targets() {
    return ["frame"];
  }

  initialize() {
    this.tableTbodyTarget = document
      .querySelector(this.element.getAttribute("data-table"))
      .querySelector("tbody");
  }

  connect() {
    this.copyRows();
    this.observeFrames();
  }

  observeFrames() {
    const observer = new MutationObserver((mutations) => {
      debugger;
    });

    observer.observe($("#my_frame")[0], { childList: true });
  }

  copyRows() {
    this.tableTbodyTarget.innerHTML = "";
    // this.element.querySelectorAll("tr").forEach((row) => copyRow(row));
    this.frameTargets.forEach((frame) => this.copyRowFromFrame(frame));
  }

  copyRowFromFrame(frame) {
    const row = frame.querySelector("template").content;
    console.log(row);
    this.tableTbodyTarget.appendChild(row);
  }
}
