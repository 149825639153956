import { Controller } from "stimulus"

// kopieert een value naar een andere plaats
export default class extends Controller {
  static get targets() {
    return ["source", "destination"]
  }

  perform(e) {
    e.preventDefault()
    this.destinationTarget.value = Number(this.sourceTarget.innerHTML)
  }
}
