import { Controller } from "stimulus";
import "select2";

export default class extends Controller {
  static values = { autosubmit: { type: Boolean, default: true } };

  connect() {
    // Mogelijks is de timeout niet meer nodig bij upgrade stimulus.
    // Zonder timeout: bij klikken op vorige, en dan volgende + search field
    // gebruikern => geen zoekresultaten
    setTimeout(() => this.initializeSelect2(), 1);
  }

  disconnect() {
    // vermijden dat er meerdere select2's verschijnen op het scherm
    $(this.select2Select).select2("destroy");
  }

  initializeSelect2() {
    const selectConfig = {
      placeholder: "",
      allowClear: false,
      width: "250px",
    };
    $(this.element).select2(selectConfig);
    this.bindForm();
    this.openAndFocus();
  }

  openAndFocus() {
    $(this.select2Select).select2("open");
    $(this.searchField)[0].focus();
  }

  bindForm() {
    if (this.autosubmitValue) {
      $(this.select2Select).on("select2:select", function (e) {
        e.target.closest("form").submit();
      });
    }
  }

  get originalSelect() {
    return this.element;
  }

  get select2Select() {
    return this.element.parentNode.querySelector(".select2-hidden-accessible");
  }

  get searchField() {
    // element zit niet genest onder this.element.parentNode
    return document.querySelector(".select2-search__field");
  }
}
