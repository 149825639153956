import { Controller } from "stimulus";

// use render "admin/components/blur_text"
export default class extends Controller {
  connect() {
    this.blur();

    this.element.style.display = "inline";
    this.element.style.cursor = "pointer";
  }

  toggleBlur() {
    if (this.data.get("blurred") === "true") {
      this.unblur();
    } else {
      this.blur();
    }
  }

  blur() {
    this.data.set("blurred", "true");

    this.element.style.color = "transparent";
    this.element.style.textShadow = "0 0 5px rgba(0,0,0,0.5)";

    this.element.title = "Klik om te zien";
  }

  unblur() {
    this.data.set("blurred", "false");

    this.element.style.color = "inherit";
    this.element.style.textShadow = "none";

    this.element.title = "Klik om te verbergen";
  }
}
