import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["panel"];

  toggle(event) {
    event.preventDefault();

    this.panelTargets.forEach(async (target) => {
      if (target.classList.contains("hidden")) {
        target.classList.remove("hidden");
        await this.animate(target, "opacity-0", "opacity-100");
      } else {
        await this.animate(target, "opacity-100", "opacity-0");
        target.classList.add("hidden");
      }
    });
  }

  async animate(target, removeClass, addClass) {
    await this.nextFrame();

    target.classList.remove(removeClass);
    target.classList.add(addClass);

    await this.afterTransition(target);
  }

  // a small function to allow transitions to happen
  nextFrame() {
    return new Promise((resolve) => {
      requestAnimationFrame(() => {
        requestAnimationFrame(resolve);
      });
    });
  }

  // makes sure no transitions are happening
  afterTransition(element) {
    return Promise.all(
      element.getAnimations().map((animation) => animation.finished)
    );
  }
}
