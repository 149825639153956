import { Controller } from "stimulus"

// zet de focus bij laden op target "field"
export default class extends Controller {
  static get targets() {
    return ["field"]
  }

  connect() {
    this.fieldTarget.focus()
  }
}
